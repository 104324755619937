
import * as cloudinaryRuntime$rHG6y6GR1p from '/opt/build/repo/node_modules/@nuxt/image/dist/runtime/providers/cloudinary'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "cloudinary",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['cloudinary']: { provider: cloudinaryRuntime$rHG6y6GR1p, defaults: {"baseURL":"https://res.cloudinary.com/smoxy/image/upload/v1658756929/web","modifiers":{"effect":"sharpen:100","quality":"auto:best"}} }
}
        